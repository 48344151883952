import React, { useState, useRef, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import clsx from 'clsx';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
// import CircularProgress from '@material-ui/core/CircularProgress';

import Timeline from 'components/Timeline';
import ScrollUpButton from 'components/ScrollUpButton';
import FillableContainer from 'components/FillableContainer';
import PositionCardContainer from './PositionCardContainer';
// import PositionCreateShortcut from './PositionCreateShortcut';
import Claims from '../claims/Claims';

// import { fetchPositions } from './store/positions.slice';
import { resetScrollToBottom } from '../store/discuss.slice';

const useStyles = makeStyles(theme => ({
  root: {
    // border: 'solid black 1px', // DEBUG
    height: '100%',
  },
  innerWrapper: {
    display: 'flex', flexGrow: 0,
    height: '100%',
  },
    timeline: { flexBasis: '100%' },
      columns: {
        display: 'flex',
        flexFlow: 'row wrap',
        '& > :nth-child(n)': {
          alignSelf: 'flex-start',
          width: '50%',
        },
        '& > :nth-child(even)': {
          marginTop: theme.spacing(10),
          // marginLeft: 'auto',
        },
        '& > :nth-child(1)': { marginTop: theme.spacing(8) },
        '& > :nth-child(2)': { marginTop: theme.spacing(16) },
        '& > :nth-last-child(n)': { marginBottom: theme.spacing(8) },
      },
  cardsContainer: {
    height: '100%',
    overflowY: 'auto',
  },
    cardWrapper: {
      // border: 'solid black 1px', // DEBUG
      position: 'relative',
      // padding: `0px ${theme.spacing(1)}`,
    },
      card: { width: '92%' },
        leftCard: { margin: '0px auto 0px 10px' },
        rightCard: { margin: '0px 10px 0px auto' },
      dot: {
        borderRadius: '50%',
        backgroundColor: theme.palette.secondary.main,
        width: 19, height: 19,
        position: 'absolute', top: 20,
      },
        leftRedDot: { right: -10 },
        rightRedDot: { left: -9 },
  scrollUpButton: {
    position: 'absolute',
    right: theme.spacing(3),
    bottom: theme.spacing(2),
  },
}));

const getSide = (index) => {
  return index%2 === 0 ? 'left' : 'right';
};

function Positions(props) {
  const {
    className,
    pending,
    debate,
    positions,
    scrollToBottom,
    onResetScrollToBottom,
  } = props;
  const classes = useStyles();

  const scrollerRef = useRef(null);
  const [scrollable, setScrollable] = useState(false);
  const [scrollStatus, setScrollStatus] = useState(null);

  const onScroll = (event) => {
    const el = event.currentTarget;
    const top = el.scrollTop;
    const maxScroll = el.scrollHeight - el.clientHeight;
    if (0 <= top && top <= 20) {
      setScrollStatus('top')
    } else if (maxScroll - 20 <= top && top <= maxScroll) {
      setScrollStatus('bottom');
    } else {
      setScrollStatus(null);
    }
  };

  const mapCards = (items) => {
    return items &&
      items.map((item, index) => {
        return (
          <div className={classes.cardWrapper} key={index}>
            <div className={clsx(
              classes.dot,
              classes[`${getSide(index)}RedDot`],
            )}>&nbsp;</div>

            <PositionCardContainer
              className={clsx(
                classes.card,
                classes[`${getSide(index)}Card`]
              )}
              position={item}
              side={getSide(index)}
            >
              {/* <Claims position={item} /> */}
            </PositionCardContainer>

          </div>
        );
      });
  }

  const cbResetScrollToBottom = useCallback(onResetScrollToBottom, []);
  useEffect(() => {
    if (scrollToBottom) {
      const el = scrollerRef.current;
      if (Boolean(el)) {
        const boundingHeight =  el.getBoundingClientRect().height;
        const totalHeight = el.scrollHeight;
        setScrollable(totalHeight > boundingHeight);
        el.scrollTo({
          top: el.scrollHeight,
          behavior: 'smooth',
        });
        setTimeout(() => {
          cbResetScrollToBottom();
        }, 1000);
      }
    }
  }, [scrollToBottom, cbResetScrollToBottom]);

  // console.log('Positions', props); // DEBUG

  return (
    <div className={clsx(classes.root, className)}>
      <FillableContainer
        empty={_.isEmpty(positions)}
        message="There are no positions yes."
      >
        <div className={classes.innerWrapper}>
          <Timeline
            className={classes.timeline}
            startLabel={
              <Moment format="MMM YYYY">
                {_.get(debate, 'metadata.createdDate', '')}
              </Moment>
            }
            endLabel="TODAY"
            scrollable={scrollable}
            scrollStatus={scrollStatus}
          >

            <div
              ref={scrollerRef}
              className={clsx(
                classes.cardsContainer,
                classes.columns
              )}
              onScroll={onScroll}
            >
              {mapCards(positions)}
            </div>

            <ScrollUpButton
              className={classes.scrollUpButton}
              // anchorEl={anchorScrollableContainer}
              // show={Boolean(anchorScrollableContainer)}
            />
          </Timeline>
        </div>
      </FillableContainer>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const layoutState = state.discuss.layout;
  const positionsState = state.discuss.positions;
  return {
    debate: ownProps.debate,
    pending: positionsState.pending,
    positions: Object.values(positionsState.byId), // @todo memoize with selector
    scrollToBottom: layoutState.scrollToBottom,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onResetScrollToBottom: () => dispatch(resetScrollToBottom())
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Positions);
